import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import SearchBox from "../SearchBox";

const NavBar = ({ toggleNavbar, isActive }) => (
  <StaticQuery
    query={graphql`
      query SearchIndexQuery {
        siteSearchIndex {
          index
        }
      }
    `}
    render={data => (
      <nav
        className="navbar is-fixed-top no-print"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src="/img/icon-logo-sm.png" />
            <strong>ITeach Recruiters</strong>
          </Link>
          <button
            className={`button navbar-burger ${isActive ? "is-active" : ""}`}
            data-target="navMenu"
            onClick={toggleNavbar}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
        <div
          className={`navbar-menu ${isActive ? "is-active" : ""}`}
          id="navMenu"
        >
          <div className="navbar-end">
            {/* <SearchBox searchIndex={data.siteSearchIndex.index} /> */}
            <Link className="navbar-item" to="/courses">
              Courses
            </Link>
            <Link className="navbar-item" to="/about">
              About / FAQ
            </Link>
            {/* <Link className="navbar-item" to="/pricing">
              Pricing
            </Link> */}
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">Tools</a>
              <div className="navbar-dropdown">
                <Link className="navbar-item" to="/job-keyword-tool">
                  Keyword Explainer
                </Link>
                <Link className="navbar-item" to="/tech-terms">
                  Tech Terms
                </Link>
                <Link className="navbar-item" to="/keyword-graph">
                  Keyword Graph
                </Link>
                {/* <Link className="navbar-item" to="/tech-stack-diagram">
                  Tech Stack Diagram
                </Link> */}
                {/* <Link className="navbar-item" to="/ai-resume-analyzer">
                  AI Resume Analyzer
                </Link> */}
              </div>
            </div>

            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">Explained...</a>
              <div className="navbar-dropdown">
                <Link
                  className="navbar-item"
                  to="/blog/a-tour-of-10-most-common-database-systems-for-recruiters/"
                >
                  Databases (10 common types)
                </Link>
                <Link
                  className="navbar-item"
                  to="/blog/defi-and-smart-contract-programming-explained-for-recruiters/"
                >
                  DeFi &amp; Smart Contracts
                </Link>
                <Link
                  className="navbar-item"
                  to="/blog/devops-explained-for-recruiters/"
                >
                  DevOps
                </Link>
                <Link
                  className="navbar-item"
                  to="/blog/docker-explained-visually-for-non-technical-folks/"
                >
                  Docker
                </Link>

                <Link
                  className="navbar-item"
                  to="/blog/frontend-vs-backend-developers-for-recruiters/"
                >
                  Frontend Vs Backend
                </Link>
                <Link
                  className="navbar-item"
                  to="/blog/rest-vs-graphql-for-recruiters/"
                >
                  GraphQL Vs REST
                </Link>

                <Link
                  className="navbar-item"
                  to="/blog/java-vs-javascript-definitive-guide-for-recruiters/"
                >
                  Java Vs JavaScript
                </Link>
                <Link
                  className="navbar-item"
                  to="/blog/java-vs-microsoft-dot-net-for-recruiters/"
                >
                  Java Vs .NET
                </Link>

                <Link
                  className="navbar-item"
                  to="/blog/jobs-on-a-software-scrum-team-for-recruiters/"
                >
                  Jobs On A Scrum Team
                </Link>

                <Link
                  className="navbar-item"
                  to="/blog/message-queues-and-kafka-explained-in-plain-english/"
                >
                  Kafka (And Message Queues)
                </Link>

                <Link
                  className="navbar-item"
                  to="/blog/mobile-vs-web-developers-a-guide-for-recruiters/"
                >
                  Mobile Devs Vs Web Devs
                </Link>
                <Link
                  className="navbar-item"
                  to="/blog/npm-and-other-package-managers-for-recruiters/"
                >
                  npm (And Package Managers)
                </Link>
                <Link className="navbar-item"
                  to="/blog/python-and-data-science-for-recruiters/">
                  Python &amp; Data Science
                </Link>
                <Link className="navbar-item" to="/blog/query-languages-explained-for-recruiters/">
                  Query Languages
                </Link>
                <Link
                  className="navbar-item"
                  to="/blog/serverless-systems-explained-for-recruiters/"
                >
                  Serverless
                </Link>
                <Link
                  className="navbar-item"
                  to="/blog/static-typing-and-typescript-explained-for-recruiters/"
                >
                  Static Typing &amp; TypeScript
                </Link>

                <Link
                  className="navbar-item"
                  to="/blog/version-control-systems-explained-for-recruiters/"
                >
                  Version Control Systems (e.g. git)
                </Link>
                <Link
                  className="navbar-item"
                  to="/blog/common-web-application-architecture-explained-for-recruiters/"
                >
                  Web App Architecture
                </Link>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">Surveys</a>
              <div className="navbar-dropdown">
                <Link
                  className="navbar-item"
                  to="/blog/why-dont-developers-respond-to-recruiters-a-survey/"
                >
                  September 2019 Developer Survey
                </Link>
                <Link
                  className="navbar-item"
                  to="/blog/august-2019-tech-recruiter-survey-results/"
                >
                  August 2019 Recruiter Survey
                </Link>
                <Link
                  className="navbar-item"
                  to="/blog/january-2020-developer-survey-on-recruiting/"
                >
                  January 2020 Developer Survey
                </Link>
                <Link
                  className="navbar-item"
                  to="/blog/january-2022-developer-survey-on-recruiting/"
                >
                  January 2022 Developer Survey
                </Link>
              </div>
            </div>

            <Link className="navbar-item" to="/blog">
              Blog
            </Link>
            <a
              className="navbar-item"
              rel="noopener norefferer"
              target="_blank"
              href="https://www.linkedin.com/in/aaron-r-decker/"
            >
              <img style={{ height: "2rem" }} src="/img/ln-icon.svg" />
            </a>
            <a
              className="navbar-item"
              rel="noopener norefferer"
              target="_blank"
              href="https://twitter.com/dynamicdoublea"
            >
              <img
                style={{ height: "2rem" }}
                src="/img/twitter-rounded-square.png"
              />
            </a>

            <div className="navbar-item">
              <Link className="button is-primary" to="/contact">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    )}
  />
);

export default NavBar;
