import React from 'react'
import config from '../../../config'
import { Link } from 'gatsby'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='content has-text-centered'>
          <p>
            {config.copyright} | <Link to='/contact' className='has-text-weight-semibold'>Contact</Link> | <a href='/rss.xml' target='_blank'>RSS</a>{' '}
            | <a href='/sitemap.xml' target='_blank'>Sitemap</a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
