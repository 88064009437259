import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

h1, h2 {
  color: #0b5577;
}

h3, h4, h5, p {
  color: #333;
}

a {
  color: #1498d5;
}
a:hover {
  color: #5fc2ef;
}
a:visited {
  color: #0b5577;
}

.primary-color {
  color: #0b5577;
}

.banner{
  background-color: #49be25;
  color: "#fff";
  text-align: center;
  padding: 10px 10px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  margin-bottom: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.banner-transition-appear {
  opacity: 0.01;
}
.banner a {
  color: #fff;
}
.banner a:hover {
  color: #ddd;
}
.banner-transition-appear.banner-transition-appear-active {
  opacity: 1;
  transition: opacity 2s ease;
}

.banner-transition-leave {
  opacity: 1;
}

.banner-transition-leave.banner-transition-leave-active {
  opacity: 0.01;
  transition: opacity 2s ease;
}
`
